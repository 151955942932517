<template>
  <div class="wrapper">
    <div class="background welcome">
      <div class="container">
        <div class="columns">
          <b-col class="col-lg-8 left">
            <h2>¡BIENVENIDO!</h2>
            <h4>¡Te damos la bienvenida al Servicio de Selección de personal  y Prácticas en Empresas!</h4>
            <p>
              En el Club de Ejecutivos podrás encontrar una amplia variedad de servicios y
              candidatos para cubrir las necesidades de talento de tu empresa, sea en
              el ámbito de la contratación laboral, o en el contexto de las prácticas
              en empresas.
              <br>
              Trabajamos en toda España con un equipo de expertos en Recursos Humanos
              asociados al club dando servicios a una amplia variedad de empresas y
              aportando candidatos de gran valor añadido a sus procesos de selección.
            </p>
          </b-col>
          <b-col class="col-lg-4 right">
            <div class="lines">
              <div id="access">Acceder al portal de empleo</div>
              <p id="access-button">
                  ACCEDER 
                  <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
              </p>
              <p id="content">Si aún no dispones de unas claves para acceder, contacta con nosotros para solicitarlas</p>
              <p id="contact" @click="$router.push('/empleabilidad/equipo#our-team')">
                Contáctanos
                <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
              </p>
            </div>
            <div id="download">Descarga el Manual de Usuario</div>
          </b-col>
        </div>
      </div>
    </div>
    <div class="background second">
      <div class="container">
        <b-row class="columns second">
          <b-col class="col-lg-6 left">
            <div class="element">
              <div class="head">
                <div class="icon">
                  <font-awesome-icon :icon="['fas', 'handshake']" class="circled-icon"/>
                </div>
                <div class="titles">
                  <h5>Contratación</h5>
                  <h4>LABORAL</h4>
                </div>
              </div>
              <p class="content-title">En el área de la contratación laboral</p>
              <p class="content-content">
                El club te puede proporcionar candidatos para cualquier área funcional
                y para cualquier nivel jerárquico. Sumamos 24 años de experiencia que
                nos permite contar con un banco de profesionales muy heterogéneo y rico
                en diversidad a nivel internacional.
              </p>
            </div>
          </b-col>
          <b-col class="col-lg-6 right">
            <div class="element">
              <div class="head">
                <div class="icon">
                  <font-awesome-icon :icon="['fas', 'user-graduate']" class="circled-icon"/>
                </div>
                <div class="titles">
                  <h5>Prácticas en</h5>
                  <h4>EMPRESAS</h4>
                </div>
              </div>
              <p class="content-title">En el área de las prácticas en empresas (becarios)</p>
              <p class="content-content">
                El Club de Ejecutivos te ofrece candidatos actualmente matriculados en las diferentes
                áreas de posgrado, másteres y carreras universitarias de toda la red de universidades
                y Escuelas de negocios que participan en nuestra red de Partnership.
                <br>
                Te ofrecemos soluciones para tus necesidades en todas las áreas funcionales (marketing,
                ventas, comunicación, publicidad, finanzas, recursos humanos, informática,internacionalización,
                logística, gestión general, etc.) así como una tipología muy amplia de candidatos a nivel
                funcional (nivel departamental, mandos medios, direcciones, direcciones generales, etc.).
              </p>
            </div>
          </b-col>
          <p id="conent">
            Te ofrecemos soluciones para tus necesidades en todas las áreas funcionales
            (marketing, ventas, comunicación, publicidad, finanzas, recursos humanos,
            internacionalización, logística, gestión general, etc.) así como una tipología
            muy amplia de candidatos a nivel funcional (nivel departamental, mandos medios,
            direcciones, direcciones generales, etc.).
          </p>
        </b-row>
      </div>
    </div>
     <b-row class="we-work">
      <div class="background second">
        <div class="container">
          <p class="title">¿CÓMO <span>TRABAJAMOS?</span></p>
        </div>
        <div class="bg"></div>
        <div class="container">
          <div class="columns cards">
            <b-col class="cardas one col-sm-12 col-md-5 col-lg-2">
              <b-row class="title">
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
                <span>1</span>
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
              </b-row>
              <p>Recepción del perfil profesional que busca la empresa.</p>
              <div class="hero hero-one"></div>
            </b-col>
            <b-col class="cardas two col-sm-12 col-md-5 col-lg-2">
              <b-row class="title">
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
                <span>2</span>
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
              </b-row>
              <p>
                Fase de reclutamiento y preselección por parte del departamento localizando
                a las personas que se ajustan al perfil demandado por la empresa.
              </p>
              <div class="hero hero-two"></div>
            </b-col>
            <b-col class="cardas three col-sm-12 col-md-5 col-lg-2">
              <b-row class="title">
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
                <span>3</span>
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
              </b-row>
              <p>
                Envío de los candidatos a la empresa. La empresa lleva adelante el proceso
                de entrevistas como considera más oportuno.
              </p>
              <div class="hero hero-three"></div>
            </b-col>
            <b-col class="cardas four col-sm-12 col-md-5 col-lg-2">
              <b-row class="title">
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
                <span>4</span>
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
              </b-row>
              <p>
                Seguimiento del proceso por si es necesario efectuar modificaciones,
                reajustes, etc.
              </p>
              <div class="hero hero-four"></div>              
            </b-col>
            <b-col class="cardas five col-sm-12 col-md-5 col-lg-2">
              <b-row class="title">
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
                <span>5</span>
                <svg height="2" width="25%">
                  <line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgb(255,255,255);stroke-width:2" />
                </svg>
              </b-row>
              <p>Decisión final de la empresa y comunicación.</p>
              <div class="hero hero-five"></div>              
            </b-col>
          </div>
        </div>
      </div>
    </b-row>
    <b-row class="bottom-section">
      <div class="container">
        <b-row class="columns">
          <b-col class="cols-12 col-lg-6 left">
            <h2>
              ¿Cuáles son los signos de
              <br>
              <span>
                IDENTIDAD DEL SISTEMA?
              </span>
            </h2>
            <ul class="signs-list">
              <li>
                <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
                Servicio experto por los miembros del club.
              </li>
              <li>
                <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
                Rapidez de la respuesta: 48 horas desde la recepción del perfil.
              </li>
              <li>
                <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>                
                Confidencialidad y discreción garantizada.</li>
              <li>
                <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
                Rigurosidad en la preselección de candidatos.</li>
              <li>
                <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
                Soluciones a nivel nacional (servicio presente en toda España).</li>
            </ul>
          </b-col>
          <b-col class="cols-12 col-lg-6 right">
            <h2>
              ¿Qué otras cosas
              <br>
              <span>
                PODEMOS HACER JUNTOS?
              </span>
            </h2>
            <p id="content">
              Además de este servicio que os ofrecemos, en el Club de Ejecutivos estamos en
              constante colaboración con el entorno empresarial desarrollando todo tipo de
              actividades vinculadas a la mejora de la empleabilidad de nuestros colectivos:
              organización de seminarios, talleres, ponencias, conferencias, recursos,
              mejores prácticas, ferias de empleo, etc.
            </p>
          </b-col>
        </b-row>
      </div>
    </b-row>
    <b-row class="clearfix" style="height: 50px;background: rgb(243, 243, 243);"></b-row>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('empleabilidad.business_area'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.business_area') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.business_area') + ' - Club de Ejecutivos' },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {

  .container {
    text-align: left;
  }

  .background.welcome {
    background: #d3d5d6;
  }

  .columns { 
    display: flex;
    flex-wrap: wrap;
    
    .right {
      .lines {
        background: url('/images/work/lines.png') no-repeat top center;
        width: 367px;
        height: 231px;
      }
    }
  }

  .we-work .bg {
    width: 105vw;
    background: url('/images/work/bg-how-we-work.gif') repeat-x top center;
    height: 70px;
  }

  .background.second {
    background: #f4edee;
    padding-bottom: 50px;
  }

  .we-work {

    p.title {
      font-size: 1.8rem;
      margin: 0;
      margin-bottom: -35px;

      span {
        font-weight: 800;
      }
    }

    .columns.cards {
      display: flex;
      justify-content: center;
    }

    .cardas {
      // width: 14.1%;
      padding: 20px;
      margin-top: -29px;
      border-radius: 0;
      margin-right: 20px;
      flex-basis: auto;
      margin-bottom: 50px;
    }

    .cards {
      .cardas {
        color: white;
        font-size: 0.8rem;
      }
      .one {
        background: #38bcdd;
      }
      .two {
        background: #169fdb;
      }
      .three {
        background: #0082c3;
      }
      .four {
        background: #009090;
      }
      .five {
        background: #184a5f;
      }
    }
  }

  .circled-icon {
    padding: 10px;
    border: 2px solid#2c3e50;
    border-radius: 50%;
    /* font-size : 60px; */
    height: 60px;
    width: 60px;
    text-align: center;
  }
  
  .head {
    margin: 0;

    h2 {
      font-weight: 800;
      font-size: 1.8rem;
    }

    p {
      font-weight: 700;
    }
  }

  .element {
    // height: 300px;

    .content-title {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 600;

      .content-content {
        font-size: 0.9rem;
      }
    }

    .head {
      display: flex;
      height: auto;

      .titles {
        h4 {
          font-weight: 800;
        }

        h5 {
          font-size: 1.5rem;
        }
      }
    }
    p {
      padding: 0;
    }

    .icon {
      margin-right: 20px;
      padding: 0;
    }
  }

  .columns.second .left {
    border-right: 2px solid #2c3e50;
  }

  .columns.second {
    .right {
      padding-left: 50px;
    }

    p#conent {
      margin: 15px;
      padding-top: 25px;
      font-size: 0.9rem;
    }
  }

  .columns.second {
    padding-top: 50px;
  }

  .cardas {
    .title {
      height: 25px;
      
      span {
        color: white;
        font-size: 2rem;
        font-weight: 600;
        top: -22px;
        position: relative;
        /* margin: auto; */
        margin: 0 10px;
      }

      svg {
        margin: 0 auto;
      }
    }
  }
}

.welcome {
  .container {
    padding: 0;
  }

  .columns {
    padding: 50px 0;

    .col {
      flex-basis: auto;
    }

    .left {
      padding-bottom: 50px;
      padding-right: 10%;

      h2 {
        font-weight: 800;
      }
      h4 {
        font-size: 1rem;
        font-weight: 600;
      }
      p {
        font-size: 0.9rem;
      }
    }

    .right {
      flex-basis: 0;
      flex-grow: 0;
      margin: auto;

      #download {
        text-align: center;
        font-size: 0.8rem;
        text-decoration: underline;
        cursor: pointer;
      }

      #download:hover {
        text-decoration: none;
      }

      .lines {
        text-align: center;
        
        #access {
          font-size: 1.3rem;
          font-weight: 600;
          position: relative;
          top: -15px;
        }
        #access-button {
          background: #009090;
          width: 40%;
          margin: 0 auto;
          padding: 8px;
          color: white;
          font-weight: 600;
          font-size: 1.2rem;
          cursor: pointer;
        }
        #access-button:hover {
          opacity: 0.7;
        }
        #content {
          font-size: 0.95rem;
          width: 80%;
          margin: 10px auto;
        }
        #contact {
          color: #009090;
          font-weight: 800;
          text-transform: uppercase;
          font-size: 1.2rem;
          cursor: pointer;
        }
        #contact:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cardas {
    margin-right: 0px !important;
  }

  .cardas p {
      text-align: center !important;    
  }

  .columns.second {
    .left.col {
      border-right: none;
      padding-bottom: 25px;
    }

    .right.col {
      padding-left: 15px;
    }
  }

  .welcome .columns .right {
    flex-basis: auto;

    .lines {
      text-align: left;
      background: unset;
      height: unset;

      #content {
        width: 90%;
        margin: 10px 0;
      }

      #access-button {
        text-align: center;
        margin: 0;
      }
    }
    #download {
      text-align: left;
    }
  }
}

.hero {
  position: absolute;
  border-style: solid;
  border-width: 50px 92px 0 93px;
  bottom: -50px;
  left: 0;
  display: block;
}

.hero-one {
  border-color: #38bcdd transparent transparent transparent;    
}
.hero-two {
  border-color: #169fdb transparent transparent transparent;    
}
.hero-three {
  border-color: #0082c3 transparent transparent transparent;    
}
.hero-four {
  border-color: #009090 transparent transparent transparent;    
}
.hero-five {
  border-color: #184a5f transparent transparent transparent;    
}

@media screen and (max-width: 1199px) {
  .hero {
    display: none;
  }
}

.bottom-section {
  background: #1a171b;
  padding-bottom: 50px;
  color: white;

  .columns {
    padding-top: 50px;

    span {
      font-weight: 800;
    }

    h2 {
      font-size: 1.6rem;
      width: 50%;
      height: 150px;
    }
  }

  .left {
    border-right: 1px solid white;
    padding-right: 50px;

    .signs-list {
      list-style-type: none;
      padding: 0;

      li {
        border-bottom: 1px solid white;
        margin-bottom: 20px;
      }
    }

    .icon-check {
      font-size: 1.5rem;
    }
  }

  .right {
    padding-left: 50px;
  }
}

@media screen and (max-width: 991px) {
  .bottom-section {
    .columns {
      width: 90%;
      margin: auto;

      .col {
        flex-basis: unset;
      }
      h2 {
        height: auto;
        width: 100%;
      }
      .left {
        padding: 0;
        border-right: none;
        padding-bottom: 25px;
      }
      .right {
        padding-left: 0;
      }
    }
  }
}
</style>